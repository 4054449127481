/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";
@import "theme-utilities.less";


//
// wordpress
// --------------------------------------------------
img.alignleft{
  float: left;
  margin-right: 1em;
  margin-bottom: 1em;
  &:after{
    content: "";
    clear: both;
    overflow: hidden;
  }
}
img.aligncenter{
  text-align: center;
  display: block;
  &:after{
    content: "";
    clear: both;
    overflow: hidden;
  }
}
img.alignright{
  float: right;
  margin-left: 1em;
  margin-bottom: 1em;
  &:after{
    content: "";
    clear: both;
    overflow: hidden;
  }
}

.screen-reader-response{
  display: none;
  padding: 15px;
  border: 1px solid @brand-danger;
  margin-top: 30px;
  margin-bottom: 30px;
  ul{
    color: @brand-danger;
  }
}

.wpcf7-not-valid-tip{
  color: @brand-danger; 
}

.wpcf7-validation-errors{
  display: none;
}

//
// headline
// --------------------------------------------------
h2{
  text-align: center;
  padding-top: 80px;
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 0px;
  small{
    font-weight: bold;
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
  }
  strong{
    color: @brand-primary;
  }
  &:before{
    content: url('/wp-content/themes/spanishonline/images/common/icon_h2.png');
    zoom: 0.5;
    display: block;
    margin-bottom: 20px;
  }
  @media screen and (max-width: @screen-md-max) {
    padding-top: 40px;
  }
}



//
// media
// --------------------------------------------------
.media{
  .media-left{
    padding-right: 20px;
    .inner{
      width: 100px;
      text-align: center;
      img{
        text-align: center;
        display: inline-block;
      }
    }
  }
  .media-body{
    h4{
      margin-bottom: 1em;
    }
  }
}

//
// fixed
// --------------------------------------------------
body.navbar-local-on{
  .list-localmenu{
    position: fixed;
    top: 100px;
    @media screen and (max-width: @screen-xs-max) {
      position: inherit;
      top: 0px;
    }
  }
}


//
// contact arera
// --------------------------------------------------
.contact-wrap{
  padding: 120px 0;
  background: center center url('/wp-content/themes/spanishonline/images/index/image0601.jpg') no-repeat;
  background-size: cover;
  @media screen and (max-width: @screen-xs-max) {
    padding: 60px 0;
  }
  .steps{
    dl{
      position: relative;
      dt{
        font-family: @font-family-noto-sans;
        font-weight: bold;
        margin-bottom: 20px;
        position: relative;
        i{
          display: block;
          font-style: normal;
          color: @brand-primary;
          position: relative;
          &:before{
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: @brand-primary;
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
            @media screen and (max-width: @screen-xs-max) {
              display: none;
            }
          }
          &:after{
            content: "";
            width: calc(100% - 5em);
            height: 1px;
            display: block;
            background: @brand-primary;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            @media screen and (max-width: @screen-xs-max) {
              display: none;
            }
          }
        }
      }
    }
    > div:nth-last-child(1){
      i:before{
        display: none;
      }
    }
  }
}



//
// nav-tabs
// --------------------------------------------------
.nav-tabs{
  li{
    padding-left: 1px;
    padding-right: 1px;
    a{
      background: #fafafa;
      color: #333;
      padding-top: 1em;
      padding-bottom: 1em;
      border-color: #ccc;
      border-bottom: 0px!important;
    }
    &:hover,
    &.active,
    &:focus{
      a,
      a:focus,
      a:active,
      a:hover{
        background: @brand-primary;
        border-color: @brand-primary;
        border-bottom: 0px!important;
        color: #fff;
      }
    }
  }
}

//
// list
// --------------------------------------------------
ul.list-bordered{
  li{
    border-bottom: 1px solid #ccc;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    &:last-child{
      border-bottom: 0px;
    }
  }
}

ul.list-alert{
  position: relative;
  li{
    padding-left: 1.5em;
    &:before{
      content: "*";
      position: absolute;
      left: 0.1em;
    }
  }
}

ul.list-localmenu{
  position: relative;
  font-size: @font-size-small;
  li{
    margin-bottom: 1em;
    a{
      color: #333;
      i{
        color: @brand-primary;
        vertical-align: middle;
      }
    }
  }
}


dl.dl-horizontal.dl-bordered{
  position: relative;
  dt{
    position: absolute;
    padding-top: 0.5em;
    left: 0px;
    width: 7em;
    text-align: right;
  }
  dd{
    width: 100%;
    left: 0px;
    margin-left: 0px;
    padding-left: 9em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #ccc;
  }
  &.dl-padding-l{
    dt{
      padding-top: 1em;
    }
    dd{
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}

//
// media box
// --------------------------------------------------
.media-box{
  .media-box-inner{
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
    .media-image{
      display: block;
      height: 196px;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
      }
    }
    .padding{
      padding: 15px;
      h5{
        margin-top: 0px;
      }
      p{
        font-size: @font-size-small;
      }
      small{
        display: block;
        font-size: 12px;
      }
    }
  }
}

//
// price
// --------------------------------------------------
.price-wrap{
  border: 1px solid @brand-primary;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
  .price-header{
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
    @media screen and (max-width: @screen-sm-max) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    strong{
      display: block;
      font-family: @font-family-noto-sans;
      margin: 0px;
      padding:  0px;
      small{
        display: inline-block;
      }
    }
    i{
      font-size: 50px;
      font-family: @font-family-noto-sans;
      color: @brand-primary;
      font-style: normal;
      font-weight: bold;
      line-height: 110%;
      span{
        font-size: 70px;
      }
      small{
        font-size: 35px;
        display: inline-block;
      }
    }
    small{
      display: block;
    }
  }
  .price-footer{
    text-align: center;
    a{
      width: 100%;
    }
  }
  &.price-lg{
    margin-top: 0px;
    .price-header{
      padding-top: 90px;
      @media screen and (max-width: @screen-sm-max) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  &.price-aside{
    border-color: #ccc;
    .price-header{
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;
      i{
        font-size: @font-size-h2;
        small{
          font-size: @font-size-h4;
          display: inline-block;
        }
      }
    }
  }
}

//
// slanting
// --------------------------------------------------
.slant{
  display: block;
  position: relative;
  &:before,
  &:after{
    content: "";
    position: absolute;
    z-index: 2;
    display: block;
  }
  &.slant-right-top{
    position: relative;
    &:before{
      width: 100%;
      height: 50px;
      background: #fff;
      top: -25px;
      left: 0;
      transform: skewY(1.5deg);
      @media screen and (max-width: @screen-md-max) {
        display: none;
      }
    }
  }
  &.slant-left-top{
    position: relative;
    &:before{
      width: 100%;
      height: 50px;
      background: #fff;
      top: -25px;
      left: 0;
      transform: skewY(-1.5deg);
      @media screen and (max-width: @screen-md-max) {
        display: none;
      }
    }
  }
  &.slant-left-bottom{
    position: relative;
    &:after{
      width: 100%;
      height: 50px;
      background: #fff;
      bottom: -25px;
      left: 0;
      transform: skewY(-1.5deg);
      @media screen and (max-width: @screen-md-max) {
        display: none;
      }
    }
  }
  &.slant-right-bottom{
    position: relative;
    &:after{
      width: 100%;
      height: 50px;
      background: #fff;
      bottom: -25px;
      left: 0;
      transform: skewY(1.5deg);
      @media screen and (max-width: @screen-md-max) {
        display: none;
      }
    }
  }
}

//
// slick slider
// --------------------------------------------------
.slick-slider{
  @media screen and (max-width: @screen-xs-max) {
    margin-left: 15px;
    margin-right: 15px;
  }
  .slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.slick-prev{
      left: 10%;
      cursor: pointer;
      font-size: 30px;
      z-index: 1;
      @media screen and (max-width: @screen-xs-max) {
        display: none!important;
      }
    }
    &.slick-next{
      right: 10%;
      cursor: pointer;
      font-size: 30px;
      z-index: 1;
      @media screen and (max-width: @screen-xs-max) {
        display: none!important;
      }
    }
  }
  .slick-dots{
    text-align: center;
    padding-left: 0px;
    li{
      display: inline-block;
      margin: 0 5px;
      &.slick-active,
      &:hover{
        button{
          background-color: @brand-primary;
          .transition(.4s 0s);
        }
      }
      button{
        border-radius: 100%;
        width: 15px;
        height: 15px;
        padding: 0px;
        overflow: hidden;
        font-size: 0px;
        line-height: 0;
        outline: inherit;
        border: 0px;
        .transition(.4s 0s);
      }
    }
  }
}



//
// btn
// --------------------------------------------------
.btn-group{
  .btn.btn-default{
    &:first-child{
      border-radius: 100px 0 0 100px;
    }
    &:last-child{
      border-radius: 0 100px 100px 0;
    }
  }
}
.btn{
  padding-left: 2em;
  padding-right: 2em;
  font-size: 14px;
  @media screen and (max-width: @screen-md-max) {
    font-size: 12px;
    padding-left: 1em;
    padding-right: 1em;
    white-space: normal;
  }
  .transition(.4s 0s);
  &.btn-lg{
    font-size: 16px;
    font-family: @font-family-noto-sans;
    padding-left: 4em;
    padding-right: 4em;
    @media screen and (max-width: @screen-md-max) {
      font-size: 14px;
      padding-left: 1em;
      padding-right: 1em;
      min-width: 100%;
    }
  }
  &.btn-default{
    border-color: #ccc;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
    &:hover{
      background: #fafafa;
    }
  }
  &.btn-primary{
    border-color: transparent;
    color: #fff;
    border-radius: 100px;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
  }
  &.btn-black{
    background: #333;
    border-color: transparent;
    color: #fff;
    border-radius: 100px;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
    &:hover{
      background-color: #666;
    }
  }
}




//
// navbar
// --------------------------------------------------
.navbar.navbar-default{
  background: #fff;
  box-shadow: 0 3px 3px rgba(0,0,0,0.1);
  border-radius: 0px;
  border: 0px;
  .navbar-header{
    .navbar-brand{
      padding-top: 27px;
      padding-bottom: 27px;
      height: auto;
      @media screen and (max-width: @screen-md-max) {
        padding-top: 15px;
        padding-bottom: 7px;
        img{
          zoom: 0.45;
        }
      }
    }
  }
  .navbar-collapse{
    ul.navbar-right{
      margin-right: 0px;
      li{
        margin-left: 20px;
        margin-top: 29px;

        a{
          padding: 0px;
          color: #333;
          &:active,
          &:focus{
            background: none;
          }
          &:hover{
            color: @brand-primary;
          }
        }
        &.active{
          a{
            background: none;
            &:hover{
              color: #333;
            }
          }
        }
      }
      li.login{
        margin-top: 23px;
        div.btn-group{
          a{
            font-size: 14px;
            line-height: 37px;
            padding: 0 1em;
            color: #333;
            &:first-child{
              padding-left: 2em;
            }
            &:last-child{
              padding-right: 2em;
            }
          }
        }
        @media screen and (max-width: @screen-md-max) {
          border-top: 1px solid #ccc;
          padding-top: 23px;
          padding-bottom: 23px;
          .btn-group{
            width: 100%;
            a{
              width: 50%;
            }
          }
        }
      }
      li.apply{
        margin-top: 23px;
        margin-bottom: 23px;
        a.btn.btn-primary{
          line-height: 37px;
          font-size: 14px;
          color: #fff;
          padding: 0 2em;
          &:hover{
            background: darken(@btn-primary-bg, 5%);
          }
        }
      }
    }
  }
}

//
// footer
// --------------------------------------------------
footer{
  padding: 80px 0 10px 0;
  background: #333;
  color: #fff;
  ul{
    font-size: @font-size-small;
    li{
      border-bottom-color: #666!important;
      a{
        color: #fff;
        &:hover{
          color: @brand-primary;
        }
      }
    }
  }
}

//
// pages
// --------------------------------------------------
body.pages{

  h3{
    position: relative;
    padding: 0.7em;
    color: @brand-primary;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
    &:before{
      content: "";
      width: 70%;
      height: 1px;
      background: @brand-primary;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after{
      content: "";
      width: 70%;
      height: 1px;
      background: @brand-primary;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &.level1{
    .mainimage{
      padding: 160px 0 80px 0;
      text-align: center;
      @media screen and (max-width: @screen-xs-max) {
        padding: 80px 0 30px 0;
      }
      .inner{
        background: rgba(0,0,0,0.7);
        padding: 40px 0;
        @media screen and (max-width: @screen-xs-max) {
          padding: 20px 0;
        }
        h1,.h1{
          color: #fff;
          margin-bottom: 40px;
          @media screen and (max-width: @screen-xs-max) {
            margin-bottom: 10px;
            margin-top: 15px;
          }
        }
        .h1{
          display: block;
        }
        p{
          font-weight: bold;
          color: #fff;
          padding-left: 30px;
          padding-right: 30px;
          @media screen and (max-width: @screen-xs-max) {
            font-size: 12px;
          }
        }
      }
    }
  }
  &.level2{
    .mainimage{
      padding: 140px 0 70px 0;
      background-image: none!important;
      background-color: #fafafa!important;
      text-align: center;
      @media screen and (max-width: @screen-xs-max) {
        padding: 80px 15px 30px 15px;
      }
      h1{
        padding: 0px;
        margin: 0px;
        @media screen and (max-width: @screen-xs-max) {
          font-size: 6vw;
        }
      }
    }
    .content01{
      padding-top: 0px;
    }
    h2{
      position: relative;
      padding: 0.7em;
      color: @brand-primary;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 20px;
      @media screen and (max-width: @screen-md-max) {
        font-size: 16px;
      }
      &:before{
        content: "";
        width: 70%;
        height: 1px;
        background: @brand-primary;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        zoom: 1;
      }
      &:after{
        content: "";
        width: 70%;
        height: 1px;
        background: @brand-primary;
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    h3{
      background: #fafafa;
      padding:10px;
      color: @text-color;
      margin-top: 40px;
      margin-bottom: 20px;
      @media screen and (max-width: @screen-md-max) {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
    }
  }
}


//
// index
// --------------------------------------------------
body#index{
  section.mainimage{
    height: 100%;
    .row{
      height: 100%;
      .image-left{
        height: 100%;
        overflow: hidden;
        background: left center url('/wp-content/themes/spanishonline/images/index/mainimage01.jpg') no-repeat;
        background-size: cover;
      }
      .text{
        height: 100%;
        background: #fff;
        position: relative;
        z-index: 2;
        background: @brand-primary;
        &:before{
          content: "";
          width: 100px;
          height: 100%;
          background: @brand-primary;
          position: absolute;
          left:-52px;
          top: 0;
          transform: skewX(6deg);
          z-index: -1;
        }
        &:after{
          content: "";
          width: 100px;
          height: 100%;
          background: @brand-primary;
          position: absolute;
          right:-52px;
          top: 0;
          transform: skewX(6deg);
          z-index: -1;
        }
        .inner{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          padding-top: 85px;
          h1{
            color: #fff;
            margin: :0px;
            font-size: 2.2vw;
            small{
              color: #fff;
              display: block;
            }
          }
          small{
            color: #fff;
          }
        }
      }
      .image-right{
        height: 100%;
        background: right center url('/wp-content/themes/spanishonline/images/index/mainimage02.jpg') no-repeat;
        background-size: cover;
      }
    }

    @media screen and (max-width: @screen-sm-max) {
      .row{
        height: auto;
        .image-left{
          background: left 10px url('/wp-content/themes/spanishonline/images/index/mainimage01.jpg') no-repeat;
          height: 490px;
          background-size: cover;
        }
        .text{
          height: auto;
          transform: skewY(-6deg);
          margin-top: -4vh;
          margin-bottom: -4vh;
          &:before{
            display: none;
          }
          &:after{
            display: none;
          }
          .inner{
            transform: skewY(6deg)!important;
            padding-top: 2vh;
            padding-bottom: 2vh;
            position: inherit;
            transform: none;
            left: 0;
            top: 0;
            h1{
              font-size: 5.5vw;
              padding-top: 0px;
            }
            p{
              img{
                width: 80%;
                text-align: center;
                margin: 0 auto;
              }
            }
          }
        }
        .image-right{
          height: 440px;
          background: right -20px url('/wp-content/themes/spanishonline/images/index/mainimage02.jpg') no-repeat;
          background-size: cover;
        }
      }
    }
    @media screen and (max-width: @screen-xs-max) {
      .row{
        height: auto;
        .image-left{
          background: left 10px url('/wp-content/themes/spanishonline/images/index/mainimage01.jpg') no-repeat;
          height: 260px;
          background-size: cover;
        }
        .text{
          height: auto;
          transform: skewY(-6deg);
          margin-top: -4vh;
          margin-bottom: -4vh;
          &:before{
            display: none;
          }
          &:after{
            display: none;
          }
          .inner{
            transform: skewY(6deg)!important;
            padding-top: 2vh;
            padding-bottom: 2vh;
            position: inherit;
            transform: none;
            left: 0;
            top: 0;
            h1{
              font-size: 5.5vw;
              padding-top: 0px;
            }
            p{
              img{
                width: 80%;
                text-align: center;
                margin: 0 auto;
              }
            }
          }
        }
        .image-right{
          height: 230px;
          background: right -20px url('/wp-content/themes/spanishonline/images/index/mainimage02.jpg') no-repeat;
          background-size: cover;
        }
      }
    }
    @media screen and (max-width: @screen-sm-max) and (orientation: landscape) {
      .row{
        height: auto;
        .image-left{
          background: left 10px url('/wp-content/themes/spanishonline/images/index/mainimage01.jpg') no-repeat;
          height: 450px;
          background-size: cover;
        }
        .text{
          height: auto;
          transform: skewY(-4deg);
          margin-top: -7vh;
          margin-bottom: -7vh;
          &:before{
            display: none;
          }
          &:after{
            display: none;
          }
          .inner{
            transform: skewY(4deg)!important;
            padding-top: 2vh;
            padding-bottom: 2vh;
            position: inherit;
            transform: none;
            left: 0;
            top: 0;
          }
        }
        .image-right{
          height: 350px;
          background: right -20px url('/wp-content/themes/spanishonline/images/index/mainimage02.jpg') no-repeat;
          background-size: cover;
        }
      }
    }
    @media screen and (max-width: @screen-sm-max) {
      margin-bottom: 0px;
      height: auto;
    }
  }
  section.content01{
    .slick-video{
      margin-top: 15px;
      iframe{
        width: 100%;
      }
    }
    dl{
      margin-bottom: 0px;
      padding-top: 15px;
    }
    .slick-dots{
      margin-top: 10px;
      border-top: 1px solid #f0f0f0;
    }
  }
  section.content02{
    .text{
      padding:120px 60px;
      @media screen and (max-width: @screen-md-max) {
        padding: 60px 15px;
      }
      @media screen and (max-width: @screen-xs-max) {
        padding: 30px 15px;
      }
      i{
        font-size: 70px;
        display: block;
        color: @brand-primary;
        font-family: @font-family-serif;
        font-style: normal;
        line-height: 100%;
        @media screen and (max-width: @screen-md-max) {
          display: none;
        }
      }
      h3{
        margin-top: 5px;
        margin-bottom: 30px;
        strong{
          color: @brand-primary;
        }
      }
    }
    .content0201{
      .image{
        background: center center url('/wp-content/themes/spanishonline/images/index/image0201.jpg') no-repeat;
        background-size: cover;
        @media screen and (max-width: @screen-xs-max) {
          float: inherit;
          height: 250px!important;
          background-position: center top;
        }
        @media screen and (max-width: @screen-sm-max) and (orientation: landscape) {
          height: 400px!important;
        }
      }
    }
    .content0202{
      .image{
        background: center center url('/wp-content/themes/spanishonline/images/index/image0202.jpg') no-repeat;
        background-size: cover;
        float: right;
        @media screen and (max-width: @screen-xs-max) {
          float: inherit;
          height: 250px!important;
          background-position: center top;
        }
        @media screen and (max-width: @screen-sm-max) and (orientation: landscape) {
          height: 400px!important;
        }
      }
      .text{
        i,
        h3{
          text-align: right;
          @media screen and (max-width: @screen-xs-max) {
            text-align: left;
          }
        }
      }
    }
    .content0203{
      .image{
        background: center center url('/wp-content/themes/spanishonline/images/index/image0203.jpg') no-repeat;
        background-size: cover;
        @media screen and (max-width: @screen-xs-max) {
          float: inherit;
          height: 250px!important;
          background-position: center top;
        }
        @media screen and (max-width: @screen-sm-max) and (orientation: landscape) {
          height: 400px!important;
        }
      }
    }
  }
  .content03{
    .content0301{
      padding: 120px 0;
      background: #fafafa;
      background-size: cover;
      @media screen and (max-width: @screen-xs-max) {
        background: none;
        padding: 0;
      }
    }
    .content0302{
      padding: 80px 0;
      @media screen and (max-width: @screen-xs-max) {
        background: none;
        padding: 0;
      }
    }
    .content0303{
      padding: 120px 0;
      background: #fafafa;
      background-size: cover;
      @media screen and (max-width: @screen-xs-max) {
        background: none;
        padding: 0;
      }
    }
    .content0304{
      padding: 80px 0;
      @media screen and (max-width: @screen-xs-max) {
        background: none;
        padding: 0;
      }
    }
    @media screen and (max-width: @screen-xs-max) {
      .slant{
        &:before,
        &:after{
          display: none;
        }
      }
      .media{
        margin-bottom: 30px;
      }
    }
  }
  .content04{
    .price-wrap.price-lg{
      height: 100%;
    }
  }
  .content05{
    .bg-red{
      padding: 120px 0;
      .description{
        position: relative;
        &:before{
          font-family: @font-family-serif;
          content: "“";
          position: absolute;
          left: -50px;
          top: -20px;
          font-size: 250px;
          line-height: 150px;
          color: rgba(255,255,255,0.1);
          display:inline-block;
          @media screen and (max-width: @screen-xs-max) {
            display: none;
          }
        }
      }
      .slick-voice{
        img{
          border-radius: 100%;
          overflow: hidden;
        }
      }
      .slick-dots{
        li.slick-active{
          button{
            background: #fff;
          }
        }
      }
    }
  }
}

//
// service
// --------------------------------------------------
body#service{
  .mainimage{
    background: center center url('/wp-content/themes/spanishonline/images/service/mainimage.png') no-repeat;
    background-size: cover;
  }
  .content01{
    .nav-tabs{
      li{
        a{
          text-indent: 1em;
          min-height: 65px;
          img{
            position: relative;
            top: -0.2em;
            left: -10px;
              -webkit-filter: brightness(1);
              -moz-filter: brightness(1);
              -o-filter: brightness(1);
              -ms-filter: brightness(1);
              filter: brightness(1);
          }
        }
        &:hover,
        &.active{
          a{
            img{
              -webkit-filter: brightness(20);
              -moz-filter: brightness(20);
              -o-filter: brightness(20);
              -ms-filter: brightness(20);
              filter: brightness(20);
            }
          }
        }
        @media screen and (max-width: @screen-xs-max) {
          width: auto!important;
          float: left;
          a{
            font-size: 12px;
            padding-left: 1.5em;
            padding-right: 1.5em;
            text-indent: inherit;
            margin-bottom: 1px;
            border-radius: 6px 6px 0 0;
            min-height: 40px;
            img{
              display: none;
            }
          }
        }
      }
    }
  }
  .content02{
    .headline{
      background: @brand-primary;
      h2{
        padding: 40px 0px;
        margin: 0;
        color: #fff;
        &:before{
          -webkit-filter: brightness(20);
          -moz-filter: brightness(20);
          -o-filter: brightness(20);
          -ms-filter: brightness(20);
          filter: brightness(20);
        }
      }
    }
    @media screen and (max-width: @screen-xs-max) {
      .media-left{
        .inner{
          width: 40px;
        }
      }
      .media-object{
        zoom: 0.15;
      }
    }
  }
  .content03{
    h3{
      color: #333;
      strong{
        color: @brand-primary;
      }
      padding: 0px;
      margin: 0px;
      &:before,
      &:after{
        display: none;
      }
    }
  }
}

//
// price
// --------------------------------------------------
body#price{
  .mainimage{
    background: center center url('/wp-content/themes/spanishonline/images/price/mainimage.jpg') no-repeat;
    background-size: cover;
  }
  .content01{
    .price-wrap{
      &.price-lg{
        height: 100%;
      }
      .price-footer{
        min-height: 40px;
      }
    }
  }
  .content02{
    .inner{
      padding: 30px;
      height: 100%;
      h4{
        min-height: 3em;
      }
    }
  }
  .content03{
    .inner{
      padding: 30px;
      height: 100%;
      h4{
        min-height: 3em;
      }
    }
    img{
      @media screen and (max-width: @screen-xs-max) {
        width: 100%;
      }
    }
  }
}

//
// blog
// --------------------------------------------------
body#blog{
  &.level1{
    .mainimage{
      background: center center url('/wp-content/themes/spanishonline/images/blog/mainimage.png') no-repeat;
      background-size: cover;
    }
    .content01{
      h3{
        &.mb-xs{
          margin-bottom: 30px;
        }
      }
      .addtoany_share_save_container{
        text-align: right;
        margin-bottom: 15px;
      }
      .wp_rp_wrap{
        margin-top: 60px;
        .wp_rp_content{
          display: block!important;
          h3{
            margin-bottom: 30px!important;
            padding: 0.7em!important;
          }
          ul{
            list-style: none;
            overflow: hidden;
            clear: both;
            padding-left: 0px;
            &:after{
              content:".";
              clear: both;
              font-size: 0px;
              overflow: hidden;
            }
            li{
              width: 145px;
              float: left;
              padding: 15px;
              box-sizing: border-box;
              .wp_rp_thumbnail{
                display: block;
                border-radius: 5px;
                overflow: hidden;
                img{
                  width: 100%;
                  height: auto;
                }
              }

              .wp_rp_title{
                display: block;
                text-align: center;
                padding-top: 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
              }
            }
          }
        }

      }
    }
  }
}

//
// voice
// --------------------------------------------------
body#voice{
  &.level1{
    .mainimage{
      background: center center url('/wp-content/themes/spanishonline/images/voice/mainimage.jpg') no-repeat;
      background-size: cover;
    }
    .content01{
      ul{
        li{
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
  }
  &.level2{
    .content01{
      img{
        margin: 15px;
      }
      .question{
        &:first-letter{
          font-size: @font-size-h2;
          font-family: @font-family-noto-sans;
          font-weight: bold;
          color: @brand-primary;
        }
      }
      .answer{
        &:first-letter{
          font-size: @font-size-h2;
          font-family: @font-family-noto-sans;
          font-weight: bold;
          color: @brand-success;
        }
      }
    }
  }
}

//
// faq
// --------------------------------------------------
body#faq{
  &.level1{
    .mainimage{
      background: center center url('/wp-content/themes/spanishonline/images/faq/mainimage.jpg') no-repeat;
      background-size: cover;
    }
  }
  .content01{
    .bg-gray{
      padding: 30px;
      .h2{
        margin: 0px;
      }
    }
  }
  .content02{
    .faq-body{
      a.anchor:nth-of-type(1){
        position: relative;
        top: -100px;
      }
      h3:nth-of-type(1){
        margin-top: 0px;
      }
    }
    dl{
      dt{
        color: @brand-primary;
        margin-bottom: 1em;
        font-size: @font-size-h4;
      }
      dd{
        margin-bottom: 2em;
        padding-bottom: 2em;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

//
// progress
// --------------------------------------------------
body.free-trial{
  .media{
    .media-left{
      .media-object{
        @media screen and (max-width: @screen-xs-max) {
          zoom: 0.25;
        }
      }
    }
    .media-body{
      img{
        @media screen and (max-width: @screen-xs-max) {
          max-width: 100%;
        }
      }
    }
  }
  .paypal{
    img{
      width: 100%;
    }
  }
}
