//
// General Settings
// --------------------------------------------------
@font-family-sans-serif: Quicksand, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', '游ゴシック体', 'Yu Gothic', YuGothic, 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
@font-family-serif: "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
@font-family-noto-sans: "noto sans";

html,body{
  height: 100%;
  font-family: @font-family-sans-serif;
  @media screen and (max-width: @screen-md-max) {
    font-size: 14px;
  }
}

//
// font-family
// --------------------------------------------------
.sans-serif{
  font-family: @font-family-sans-serif;
}

.serif{
  font-family: @font-family-serif;
}

.headline{
  font-family: @font-family-noto-sans;
}

//
// font-face
// --------------------------------------------------
/* regular */
@font-face {
  font-family: 'noto sans';
  src: url('/wp-content/themes/spanishonline/fonts/NotoSansCJKjp-Black_min.woff') format('woff'),
       url('/wp-content/themes/spanishonline/fonts/NotoSansCJKjp-Black_min.eot') format('eot');
  font-weight: bold;
}

@font-face {
  font-family: 'noto sans';
  src: url('/wp-content/themes/spanishonline/fonts/NotoSansCJKjp-Regular_min.woff') format('woff'),
       url('/wp-content/themes/spanishonline/fonts/NotoSansCJKjp-Regular_min.eot') format('eot');
  font-weight: normal;
}

//
// layouts
// --------------------------------------------------
.row.no-padding{
  > div{
    padding-left: 0px;
    padding-right: 0px;
  }
}

//
// headlines
// --------------------------------------------------
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  font-family: @font-family-noto-sans;
  line-height: 1.3;
  font-weight: bold;
  small{
    color: @text-color;
    font-size: 16px;
  }
  strong{
    color: @brand-primary;
  }
}

@media screen and (max-width: @screen-xs-max) {
  body.pages{
    h1, .h1{
      font-size: 7vw;
    }
  }
  h2, .h2{
    font-size: 24px;
    small{
      font-size: 14px!important;
    }
  }
  h3, .h3{
    font-size: 20px;
    small{
      font-size: 12px!important;
    }
  }
}

//
// bg
// --------------------------------------------------
.bg-gray{
  background-color: #fafafa;
}
.bg-red{
  background-color: @brand-primary;
  color: #fff;
}

//
// img
// --------------------------------------------------
.img2x{
  zoom : 0.5;
}

//
// link
// --------------------------------------------------
a{
  display: inline-block;
  .transition(.4s 0s);
}

//
// Navbar toggle animation
// --------------------------------------------------
.navbar-toggle{
  position: relative;
  clear: both;
  overflow: hidden;
  margin-right: 8px;
  >div{
    float: left;
  }
  .icon-bar{
    position: relative;
    transition: all 500ms ease-in-out;
  }
  .icon-bar:nth-of-type(2){
    position: relative;
    top: 0px;
  }
  .icon-bar:nth-of-type(3){
    position: relative;
  }
  .icon-bar:nth-of-type(4){
    position: relative;;
    bottom: 0px;
  }
  &[aria-expanded="true"]{
    .icon-bar:nth-of-type(2){
      position: relative;
      top:6px;
      transform: rotate(45deg);
    }
    .icon-bar:nth-of-type(3){
      position: relative;;
      background-color: transparent;
    }
    .icon-bar:nth-of-type(4){
      position: relative;
      bottom: 6px;
      transform: rotate(-45deg);
    }
    .text{
      display: none;
    }
  }
  i{
    float: right;
    line-height: 100%;
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    margin-top: 1px;
  }
}

//
// Margin  and Padding
// --------------------------------------------------
/*** margin ***/
@margin-base:60px;
.mt-none{margin-top:0px!important;}
.mt-xxs{margin-top:(@margin-base * 0.25);}
.mt-xs{margin-top:(@margin-base * 0.5);}
.mt-s{margin-top:(@margin-base * 0.75);}
.mt-m{margin-top:@margin-base;}
.mt-l{margin-top:(@margin-base * 1.25);}
.mt-xl{margin-top:(@margin-base * 1.5);}
.mt-xxl{margin-top:(@margin-base * 2);}

.mb-none{margin-bottom:0px!important;}
.mb-xxs{margin-bottom:(@margin-base * 0.25);}
.mb-xs{margin-bottom:(@margin-base * 0.5);}
.mb-s{margin-bottom:(@margin-base * 0.75);}
.mb-m{margin-bottom:@margin-base;}
.mb-l{margin-bottom:(@margin-base * 1.25);}
.mb-xl{margin-bottom:(@margin-base * 1.5);}
.mb-xxl{margin-bottom:(@margin-base * 2);}

.ml-none{margin-left:0px!important;}
.ml-xxs{margin-left:(@margin-base * 0.25);}
.ml-xs{margin-left:(@margin-base * 0.5);}
.ml-s{margin-left:(@margin-base * 0.75);}
.ml-m{margin-left:@margin-base;}
.ml-l{margin-left:(@margin-base * 1.25);}
.ml-xl{margin-left:(@margin-base * 1.5);}
.ml-xxl{margin-left:(@margin-base * 2);}

.mr-none{margin-right:0px!important;}
.mr-xxs{margin-right:(@margin-base * 0.25);}
.mr-xs{margin-right:(@margin-base * 0.5);}
.mr-s{margin-right:(@margin-base * 0.75);}
.mr-m{margin-right:@margin-base;}
.mr-l{margin-right:(@margin-base * 1.25);}
.mr-xl{margin-right:(@margin-base * 1.5);}
.mr-xxl{margin-right:(@margin-base * 2);}

/*** padding ***/
@padding-base:60px;
.pt-none{padding-top:0px!important;}
.pt-xxs{padding-top:(@padding-base * 0.25);}
.pt-xs{padding-top:(@padding-base * 0.5);}
.pt-s{padding-top:(@padding-base * 0.75);}
.pt-m{padding-top:@padding-base;}
.pt-l{padding-top:(@padding-base * 1.25);}
.pt-xl{padding-top:(@padding-base * 1.5);}
.pt-xxl{padding-top:(@padding-base * 2);}

.pb-none{padding-bottom:0px!important;}
.pb-xxs{padding-bottom:(@padding-base * 0.25);}
.pb-xs{padding-bottom:(@padding-base * 0.5);}
.pb-s{padding-bottom:(@padding-base * 0.75);}
.pb-m{padding-bottom:@padding-base;}
.pb-l{padding-bottom:(@padding-base * 1.25);}
.pb-xl{padding-bottom:(@padding-base * 1.5);}
.pb-xxl{padding-bottom:(@padding-base * 2);}

.pl-none{padding-left:0px!important;}
.pl-xxs{padding-left:(@padding-base * 0.25);}
.pl-xs{padding-left:(@padding-base * 0.5);}
.pl-s{padding-left:(@padding-base * 0.75);}
.pl-m{padding-left:@padding-base;}
.pl-l{padding-left:(@padding-base * 1.25);}
.pl-xl{padding-left:(@padding-base * 1.5);}
.pl-xxl{padding-left:(@padding-base * 2);}

.pr-none{padding-right:0px!important;}
.pr-xxs{padding-right:(@padding-base * 0.25);}
.pr-xs{padding-right:(@padding-base * 0.5);}
.pr-s{padding-right:(@padding-base * 0.75);}
.pr-m{padding-right:@padding-base;}
.pr-l{padding-right:(@padding-base * 1.25);}
.pr-xl{padding-right:(@padding-base * 1.5);}
.pr-xxl{padding-right:(@padding-base * 2);}

@media screen and (max-width: @screen-sm-max) {
  @margin-base:30px;
  .mt-none{margin-top:0px!important;}
  .mt-xxs{margin-top:(@margin-base * 0.25);}
  .mt-xs{margin-top:(@margin-base * 0.5);}
  .mt-s{margin-top:(@margin-base * 0.75);}
  .mt-m{margin-top:@margin-base;}
  .mt-l{margin-top:(@margin-base * 1.25);}
  .mt-xl{margin-top:(@margin-base * 1.5);}
  .mt-xxl{margin-top:(@margin-base * 2);}

  .mb-none{margin-bottom:0px!important;}
  .mb-xxs{margin-bottom:(@margin-base * 0.25);}
  .mb-xs{margin-bottom:(@margin-base * 0.5);}
  .mb-s{margin-bottom:(@margin-base * 0.75);}
  .mb-m{margin-bottom:@margin-base;}
  .mb-l{margin-bottom:(@margin-base * 1.25);}
  .mb-xl{margin-bottom:(@margin-base * 1.5);}
  .mb-xxl{margin-bottom:(@margin-base * 2);}

  .ml-none{margin-left:0px!important;}
  .ml-xxs{margin-left:(@margin-base * 0.25);}
  .ml-xs{margin-left:(@margin-base * 0.5);}
  .ml-s{margin-left:(@margin-base * 0.75);}
  .ml-m{margin-left:@margin-base;}
  .ml-l{margin-left:(@margin-base * 1.25);}
  .ml-xl{margin-left:(@margin-base * 1.5);}
  .ml-xxl{margin-left:(@margin-base * 2);}

  .mr-none{margin-right:0px!important;}
  .mr-xxs{margin-right:(@margin-base * 0.25);}
  .mr-xs{margin-right:(@margin-base * 0.5);}
  .mr-s{margin-right:(@margin-base * 0.75);}
  .mr-m{margin-right:@margin-base;}
  .mr-l{margin-right:(@margin-base * 1.25);}
  .mr-xl{margin-right:(@margin-base * 1.5);}
  .mr-xxl{margin-right:(@margin-base * 2);}

  /*** padding ***/
  @padding-base:30px;
  .pt-none{padding-top:0px!important;}
  .pt-xxs{padding-top:(@padding-base * 0.25);}
  .pt-xs{padding-top:(@padding-base * 0.5);}
  .pt-s{padding-top:(@padding-base * 0.75);}
  .pt-m{padding-top:@padding-base;}
  .pt-l{padding-top:(@padding-base * 1.25);}
  .pt-xl{padding-top:(@padding-base * 1.5);}
  .pt-xxl{padding-top:(@padding-base * 2);}

  .pb-none{padding-bottom:0px!important;}
  .pb-xxs{padding-bottom:(@padding-base * 0.25);}
  .pb-xs{padding-bottom:(@padding-base * 0.5);}
  .pb-s{padding-bottom:(@padding-base * 0.75);}
  .pb-m{padding-bottom:@padding-base;}
  .pb-l{padding-bottom:(@padding-base * 1.25);}
  .pb-xl{padding-bottom:(@padding-base * 1.5);}
  .pb-xxl{padding-bottom:(@padding-base * 2);}

  .pl-none{padding-left:0px!important;}
  .pl-xxs{padding-left:(@padding-base * 0.25);}
  .pl-xs{padding-left:(@padding-base * 0.5);}
  .pl-s{padding-left:(@padding-base * 0.75);}
  .pl-m{padding-left:@padding-base;}
  .pl-l{padding-left:(@padding-base * 1.25);}
  .pl-xl{padding-left:(@padding-base * 1.5);}
  .pl-xxl{padding-left:(@padding-base * 2);}

  .pr-none{padding-right:0px!important;}
  .pr-xxs{padding-right:(@padding-base * 0.25);}
  .pr-xs{padding-right:(@padding-base * 0.5);}
  .pr-s{padding-right:(@padding-base * 0.75);}
  .pr-m{padding-right:@padding-base;}
  .pr-l{padding-right:(@padding-base * 1.25);}
  .pr-xl{padding-right:(@padding-base * 1.5);}
  .pr-xxl{padding-right:(@padding-base * 2);}
}

//
// Responsive Utility
// --------------------------------------------------
@media screen and (max-width: @screen-xs-max) {
  .btn-xs-block{
    display: block;
  }
}

@media screen and (max-width: @screen-sm-max) {
  .btn-sm-block{
    display: block;
  }
}
